.img {
  position: fixed;
  right: 10px;
  bottom: 10px;
  width: 150px;
  z-index: 1;
  pointer-events: none;
}

@media only screen and (max-width: 600px) {
  .img {
    width: 100px;
  }
}
