.container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.gridContainer {
  width: 80%;
  height: 80%;
  background-color: transparent;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.gridContainer4 {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.box {
  position: relative;
  border: solid 2px var(--gray);
  background-color: var(--white);
}

.middler {
  position: absolute;
  width: 15%;
  aspect-ratio: 1/1;
  border: solid 3px var(--gray);
  z-index: 1;
  background-color: var(--green);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  color: var(--black);
  cursor: pointer;
}

.wrongSides {
  background-color: var(--pink);
}

.middlerTop {
  left: 50%;
  top: 0;
  border-radius: 0 0 150px 150px;
  transform: translate(-50%, -3%);
}

.middlerTopContent {
  position: absolute;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  color: var(--black);
  padding: 0.2rem;
}

.middlerTextTopBottom {
  width: 95%;
  text-align: center;
}

.middlerBottom {
  left: 50%;
  top: 100%;
  border-radius: 150px 150px 0 0;
  transform: translate(-50%, -97%);
}

.middlerLeft {
  left: 0%;
  top: 50%;
  border-radius: 0 150px 150px 0;
  transform: translate(-3%, -50%);
  text-align: left;
  justify-content: flex-end;
}

.middlerLeft div {
  justify-self: flex-end;
}

.middlerBothSides {
  width: 50%;
  padding: 0rem 0.5rem;
}

.middlerRight {
  left: 100%;
  top: 50%;
  border-radius: 150px 0 0 150px;
  transform: translate(-96%, -50%);
  text-align: right;
}

.editPopupBg {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.514);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.editPopup {
  background-color: white;
  border: solid 3px var(--black);
  border-radius: 5px;
  width: 90%;
  max-width: 450px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1rem;
}

.input {
  border: solid 2px var(--black);
  border-radius: 5px;
  font-size: 1.1rem;
  margin-top: 1.5rem;
  padding: 0.2rem 1rem;
  width: 80%;
  font-weight: 500;
  outline: none;
}

.buttons {
  border: solid 2px var(--black);
  border-radius: 5px;
  font-size: 1.6rem;
  margin-top: 1.5rem;
  background-color: var(--white);
  cursor: pointer;
  padding: 0.2rem 0rem;
  width: 50%;
  max-width: 150px;
  font-weight: 500;
}

.nextBtn {
  font-size: 1.8rem;
  position: fixed;
  top: 80px;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--black);
  border: solid 2px var(--black);
  border-radius: 5px;
  padding: 0.2rem 0.5rem;
  cursor: pointer;
  font-family: 'sparky';
}

.nextBtn svg {
  width: 30%;
  height: 100%;
  transform: rotate(180deg);
  margin-left: 0.2rem;
}

.disabled {
  color: rgb(128, 127, 126);
  cursor: auto;
  border: solid 2px rgb(128, 127, 126);
}

.disabled svg {
  fill: rgb(128, 127, 126);
}

.backBtn {
  font-size: 1.8rem;
  position: fixed;
  top: 80px;
  left: 260px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--black);
  border: solid 2px var(--black);
  border-radius: 5px;
  padding: 0.2rem 0.5rem;
  cursor: pointer;
  font-family: 'sparky';
}

.backBtn svg {
  width: 30%;
  height: 100%;
  margin-left: 0.2rem;
}

.savePopup {
  position: relative;
  background-color: white;
  border: solid 3px var(--black);
  border-radius: 5px;
  width: 90%;
  max-width: 450px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1rem;
}

.savePopup h1 {
  margin-top: 1.5rem;
  font-size: 2rem;
  text-align: center;
}

.savePopupControls {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.saveButtons {
  font-size: 1.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--black);
  border: solid 2px var(--black);
  border-radius: 5px;
  padding: 0.2rem 0.5rem;
  cursor: pointer;
  background-color: var(--white);
  margin: 0 0.5rem;
  font-family: 'sparky';
}

.saveButtons svg {
  width: 25px;
  height: 100%;
  margin-right: 0.5rem;
}

.closePopupBtn {
  position: absolute;
  font-size: 1.5rem;
  background-color: var(--white);
  border: solid 0px var(--black);
  border-radius: 5px;
  padding: 0rem 0.5rem;
  right: 0;
  top: 0;
  cursor: pointer;
}

.mathButtonsContSingle {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-top: 0.5rem;
}

.mathButtonsCont {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.mathButtonsInner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-top: 0.5rem;
}

.mathButtons {
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--black);
  border: solid 2px var(--black);
  border-radius: 5px;
  padding: 0.2rem 0.5rem;
  cursor: pointer;
  background-color: var(--white);
  margin: 0 0.2rem;
}

.superscriptsCont {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  max-width: 100px;
  border: solid 2px var(--black);
  border-radius: 5px;
  padding: 0.2rem;
}

.output {
  width: 80%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  margin-top: 0.5rem;
}

.output h4 {
  padding: 0;
  margin: 0;
}

.smallInput {
  width: 40px;
  border: solid 2px var(--black);
  border-radius: 5px;
  font-size: 1rem;
  padding: 0.2rem 1rem;
  outline: none;
  margin-left: 0.2rem;
}

.keysCont {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

@media only screen and (max-width: 800px) {
  .gridContainer {
    width: 95%;
    margin-top: 1rem;
  }
  .middler {
    width: 40%;
    font-size: 0.8rem;
  }
}
