@font-face {
  font-family: sparky;
  src: url("./sparky.ttf");
}

:root {
  --error-color: #ec3636;
  --black: #000000;
  --white: #ffffff;
  --bg-transparent: #0000008e;
  --link-color: #2a00e6;
  --bg-blue: #0ad1f3;
  --pink: #ff00e3;
  --green: #00ef00;
  --gray: #444444;
}
