.main {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  height: 100dvh;
}

.greeting {
  /* position: absolute;
  top: 20px;
  left: 50%; */
  width: 100%;
  text-align: center;
  /* transform: translateX(-50%); */
  letter-spacing: 2px;
  font-size: 3rem;
  max-width: 90%;
  color: var(--black);
  font-weight: lighter;
  text-align: center;
}

.menu {
  padding: 2rem 1.5rem;
  width: 80%;
  max-width: 400px;
  border: solid 3px var(--black);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  background-color: var(--white);
}

.backButton {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 8%;
  aspect-ratio: 1;
  border: solid 0px var(--black);
  border-radius: 5px;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  cursor: pointer;
}

.backButton svg {
  width: 100%;
  height: 100%;
}

.selectBox {
  border: solid 2px var(--black);
  border-radius: 5px;
  font-size: 1.6rem;
  margin-top: 1.5rem;
  background-color: var(--white);
  cursor: pointer;
  padding: 0.2rem 0rem;
  width: 90%;
  font-weight: 500;
  outline: none;
}

.input {
  border: solid 2px var(--black);
  border-radius: 5px;
  font-size: 1.6rem;
  margin-top: 1.5rem;
  padding: 0.2rem 1rem;
  width: 80%;
  font-weight: 500;
  outline: none;
}

.fileInput {
  border: solid 2px var(--black);
  border-radius: 5px;
  font-size: 1.6rem;
  margin-top: 1.5rem;
  padding: 0.2rem 1rem;
  width: 80%;
  font-weight: 500;
  outline: none;
}

.buttons {
  border: solid 2px var(--black);
  border-radius: 5px;
  font-size: 1.6rem;
  margin-top: 1.5rem;
  background-color: var(--white);
  cursor: pointer;
  padding: 0.2rem 0rem;
  width: 90%;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttons svg {
  transform: rotate(180deg);
  width: 30px;
  height: 100%;
  margin-left: 0.5rem;
}

.uploadBox {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: dashed 2px #6d6d6d;
  border-radius: 5px;
  margin-top: 1.5rem;
  padding: 0.5rem;
  width: 80%;
}

.uploadFileName {
  text-align: center;
  color: #353535;
  font-weight: lighter;
}

.descriptionInput {
  font-size: 1rem;
  resize: none;
  height: 100px;
}

.errorMessage {
  font-size: 1.1rem;
  font-weight: bold;
  color: var(--error-color);
  padding: 0;
  margin: 0;
}

.message {
  font-size: 1.1rem;
  font-weight: bold;
  color: var(--pink);
}

.loadingSave svg {
  width: 30px;
  height: 100%;
  margin-right: 0.5rem;
}
@media only screen and (max-width: 1000px) {
  .greeting {
    font-size: 1.5rem;
    font-weight: 200;
  }
}


.body {
  padding: 0;
  margin: 0;
  width: 100%;
  height: calc(100vh - 120px);
  font-family: "sparky";
  letter-spacing: 0.05rem;
  overflow: hidden;
}

.button {
  font-family: "sparky" !important;
  font-weight: lighter;
}

.input2 {
  font-family: "sparky";
  font-weight: lighter;
}

.select {
  font-family: "sparky";
  font-weight: lighter;
}
