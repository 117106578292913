.container {
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 2px var(--black);
  border-radius: 10px;
  max-width: 90%;
  margin-bottom: 1rem;
  padding: 0.3rem 0.5rem;
  background-color: var(--white);
}

.url {
  text-align: left;
  font-size: 1rem;
  background-color: var(--pink);
  border-radius: 10px;
  padding: 0.3rem;
  margin: 0;
  margin-right: 0.5rem;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 2px var(--black);
  border-radius: 10px;
  background-color: transparent;
  font-size: 1.2rem;
  padding: 0.2rem 0.5rem;
  cursor: pointer;
}

.button svg {
  width: 25px;
  height: 100%;
  margin-right: 0.2rem;
}
