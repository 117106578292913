.loadingAnimation {
  animation: loading-spin 1s ease infinite;
}

@keyframes loading-spin {
  from {
    transform: rotate(1turn);
  }
  to {
    transform: rotate(0turn);
  }
}
