.container {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  height: 100vh;
}

.cardContainer {
  display: flex;
  flex-direction: column;
  width: 80%;
  max-width: 480px;
  min-height: 150px;
  max-height: 480px;
  border: solid 1px var(--black);
  border-radius: 5px;
  padding: 1rem;
  margin: 0.5rem;
  background-color: var(--white);
}

.cardText {
  display: flex;
  flex-direction: column;
}

.cardName {
  font-size: 1rem;
  padding: 0;
  margin: 0;
}
.cardSize {
  font-size: 1rem;
  padding: 0;
  margin: 0;
}

.cardDescription {
  font-size: 0.9rem;
  color: #313131;
  padding: 0;
  margin: 0;
}

.backButton {
  font-size: 1.8rem;
  position: fixed;
  top: 80px;
  left: 260px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--black);
  border: solid 2px var(--black);
  border-radius: 5px;
  padding: 0.2rem 0.2rem;
  cursor: pointer;
}

.backButton svg {
  width: 30%;
  height: 100%;
  margin-right: 0.5rem;
  font-family: "sparky";
}

.imageCont {
  width: 100%;
  height: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.puzzleImageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 100px;
  padding: 0.2rem;
}

.puzzleImage3 {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.puzzleImage4 {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.puzzleImageBox {
  border: solid 1px #696969;
}

.noPuzzles h1 {
  color: #696969;
}

.urlBox {
  width: 100%;
  margin-top: 1rem;
}

.urlBox div {
  margin: 0;
  border-width: 1px;
  max-width: 100%;
}

.urlBox h1 {
  font-size: 0.6rem;
}

.urlBox h2 {
  padding: 0;
  margin: 0;
  font-size: 1rem;
}

.urlBox button {
  font-size: 0.9rem;
  border-width: 1px;
}

.urlBox button svg {
  width: 15px;
  margin-right: 0.2rem;
}

.errorMessage {
  position: absolute;
  left: 50%;
  top: 20px;
  font-size: 1.2rem;
  padding: 0;
  margin: 0;
  color: var(--error-color);
  font-weight: bold;
  transform: translateX(-50%);
}

.actionButtons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 0.5rem;
}

.actionButton {
  border: solid 1px var(--black);
  border-radius: 50%;
  padding: 0.1rem;
  width: 30px;
  height: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  cursor: pointer;
  margin: 0 0.2rem;
}

.actionButton svg {
  width: 90%;
  height: 90%;
}

.puzzleImage {
  width: 100%;
  height: 100%;
  max-height: 300px;
}

.noImage {
  height: 1000px;
  width: 1000px;
  max-width: 100%;
  max-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px #696969;
}

.noImage h2 {
  color: #696969;
  font-size: 1.5rem;
}

.deletePopupBg {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.514);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.deletePopup {
  background-color: white;
  border: solid 3px var(--black);
  border-radius: 5px;
  width: 90%;
  max-width: 450px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1rem;
}

.deletePopup h1 {
  text-align: center;
  width: 100%;
}

.deletePopupButtonsCont {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.deletePopupButtons {
  border: solid 2px var(--black);
  border-radius: 5px;
  font-size: 1.6rem;
  margin-top: 1.5rem;
  background-color: var(--white);
  cursor: pointer;
  padding: 0.2rem 0.5rem;
  max-width: 150px;
  font-weight: 500;
  margin: 0.2rem;
}

.deletePopupButtons svg {
  width: 25px;
  height: 100%;
  margin-right: 0.5rem;
}
